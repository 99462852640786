import React from 'react';
import '../styles/Testimonials.css';

const Testimonials = () => {
  return (
    <section className="containerTestimonials">
      <h1 className="headerTestimonials">Testimonials</h1>
      <p className="paragraphTestimonials">"Harsh Solar Power Solution provided exceptional service and helped us reduce our energy bills significantly!" - Satisfied Customer</p>
    </section>
  );
};

export default Testimonials;
