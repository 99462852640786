import React from "react";
import "../styles/About.css";

const About = () => {
  return (
    <section className="containerAbout">
      <h1>About Us</h1>
      <p className="paragraphAbout">
        We are an independent Solar Power Product dealer located steps from the
        centre of town and are proud to be the quality solar product dealer in
        term of sales and services with complete installation.We are distributor
        of three renowned solar companies UTL, USHA and Smarten Power Systems
        Pvt Ltd, with their large range of solar products. Please feel free to
        contact us on WhatsApp No. 9415164872 and even come to visit our shop
        thank you.
      </p>
    </section>
  );
};

export default About;
