import React from 'react';
import '../styles/Services.css';

const Services = () => {
  return (
    <section className="containerServices">
      <h1>Our Services</h1>
      <ul>
        <li>Solar Panel Installation</li>
        <li>Solar Energy Consulting</li>
        <li>Maintenance and Support</li>
      </ul>
    </section>
  );
};

export default Services;
